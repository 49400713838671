import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import { Content, CircularImage, PageTitle } from "../globals/style";

const LawyerField = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10% 0;
`;
const Wrapper = styled.div`
  max-width: 1000px;
  height: 100%;
  margin: 8em auto;
  @media screen and (max-width: 991px) {
    margin: 3em auto;
    padding: 5%;
  }
`;
const LargerImage = styled(CircularImage)`
  width: 100%;
  max-width: 300px;
  height: auto;
  flex: 1;
  @media screen and (max-width: 991px) {
    flex-basis: 100%;
    margin: 0 auto;
  }
`;
const Info = styled.h3`
  padding: 0 50px;
  font-size: 1.5em;
  opacity: 0.87;
  @media screen and (max-width: 991px) {
    padding: 0.3em 0.3em 0 0.3em;
    margin: 0;
  }
`;
const LawyerName = styled.h2`
  font-size: 2.5em;
  padding: 0 50px;
  color: #3f6564;
  @media screen and (max-width: 991px) {
    padding: 1em 0em 0 0;
  }
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  flex: 1;
  @media screen and (max-width: 991px) {
    flex-basis: 100%;
    margin: 0 auto;
  }
`;
const OmOssPage = () => {
  const {
    allStrapiLawyers: { edges: Lawyers },
  } = useStaticQuery(
    graphql`
      query {
        allStrapiLawyers {
          edges {
            node {
              Email
              Phone
              Name
              Description
              ProfilePicture {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return (
    <Layout title="Om Advokatene" keywords={[`gatsby`, `application`, `react`]}>
      <Wrapper>
        <PageTitle>Om Advokatene</PageTitle>
        {Lawyers.map(({ node }) => (
          <>
            <LawyerField>
              <LargerImage
                fluid={
                  node.ProfilePicture &&
                  node.ProfilePicture.childImageSharp.fluid
                }
              />
              <InfoWrapper>
                <LawyerName>{node.Name}</LawyerName>
                <Info>{node.Email}</Info>
                <Info>{node.Phone}</Info>
              </InfoWrapper>
            </LawyerField>
            <Content>{node.Description}</Content>
          </>
        ))}
      </Wrapper>
    </Layout>
  );
};

export default OmOssPage;
